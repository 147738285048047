import { useState, useEffect, useRef } from "react";
import ChartHeader from "../chart-header";
import SearchHeader from "../search-header";
import Logo from "../logo";
import { menuList } from "../../../utils/menu-list";
import Link from "next/link";
import styles from "./main-navigation.module.css";
import MyAccountHeader from "../my-account-header";
import StoreNotice from "../store-notice";
import BlockMainNavigation from "./block-main-navigation";
import MenuNavigation2 from "../../../components/layout/navigation/menu-navigation-2";

const MainNavigation = (props) => {
  const [hideHeader, setHideHeader] = useState("translateY(0px)");
  const [borderBottom, setBorderBottom] = useState("unset");

  const isDark = props.dark ?? false;

  const prevScrollY = useRef(0);
  function handleScroll() {
    const { scrollTop } = document.documentElement;
    if (scrollTop < prevScrollY.current) {
      setHideHeader("translateY(0px)");
      if (window.scrollY <= 0) {
        setBorderBottom("unset");
      } else {
        setBorderBottom("0.1px solid #c1c1c1");
      }
    } else {
      // setHideHeader("translateY(-110px)");
      setHideHeader("translateY(0px)");
    }
    if (scrollTop > 0) {
      prevScrollY.current = scrollTop;
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`${styles.header} `}
        style={{ transform: hideHeader, borderBottom: borderBottom }}
      >
        <StoreNotice />
        <div className={`${styles.header_menu} ${isDark ? styles.dark : ""}`}>
          <div className={styles.header_content}>
            <Logo dark={isDark} />
            <div className={styles.main_menu}>
              {menuList.map((mainMenu, key) => (
                <div key={key} className={styles.list_menu}>
                  <Link
                    href={mainMenu.url_path}
                    className={`${mainMenu.class}`}
                  >
                    {mainMenu.title_menu}
                  </Link>
                  {mainMenu.menu.length > 0 && (
                    <div className={styles.dropdown_content}>
                      <MenuNavigation2
                        title_menu={mainMenu.title_menu}
                        listMenu={mainMenu.menu}
                        dark={isDark}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.right_corner_header}>
              <MyAccountHeader dark={isDark} />
              <ChartHeader dark={isDark} />
              <SearchHeader dark={isDark} />
            </div>
          </div>
        </div>
      </header>
      <BlockMainNavigation />
    </>
  );
};

export default MainNavigation;
